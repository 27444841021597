$color-background: '255, 255, 255';
$color-black: '40, 40, 41';
$color-black-highlight: '26, 25, 25';
$color-white: '255, 255, 255';
$color-stone: '212, 209, 199';
$color-stone-black: '49, 52, 65';
$color-cyan: '130, 160, 162';
// $color-stone: '209, 201, 194';

$ease-in-out: cubic-bezier(0, 0, 0.975, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);

$font-kumbh-sans: 'Kumbh Sans';
$font-manrope: 'Manrope';
// $font-poppins: 'Poppins';
// $font-inter: 'Inter';

$phi: 1.6180339887498948482;

$z-indexes: (
	'preloader',
	'popup',
	'cookies',
	'navigation',
	'content-front',
	'content',
	'content-back',
	'canvas'
);
