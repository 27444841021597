$breakpoints: (
	'phone': 769px,
	'tablet': 1024px,
	'laptop': 1440px,
	'desktop': 1920px,
) !default;

@import '../../node_modules/include-media/dist/_include-media.scss';

.hidden--mobile {
	@include media('<phone') {
		display: none !important;
	}
}
