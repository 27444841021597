.welcome {
	@extend %center;
	background: rgb(var(--data-color-background));
	padding-top: 0;

	@include media('<laptop') {
		padding: 4rem 64px;
	}

	@include media('<tablet') {
		padding: 4rem 0;
		padding-top: 0;
	}

	@include media('<phone') {
		padding: 4rem 0;
		padding-top: 0;
	}
}
.welcome__title {
	@extend %center;
	margin: 0;

	@include media('<tablet') {
		text-align: center;
	}

	.mobile {
		@include media('>tablet') {
			display: none;
		}
	}
	.desktop {
		@include media('<tablet') {
			display: none;
		}
	}
}
.welcome__text__wrapper {
	@include media('<phone') {
		padding: 0 24px;
	}
}
.welcome__video__wrapper {
	@extend %center;
	width: 100%;
	max-height: 72vh;
	position: relative;
	margin-bottom: 36px;
	border-radius: 18px;
	transform: perspective(800px) rotateX(0deg); // set it to -2 deg on scroll

	@include media('>laptop') {
		// margin-bottom: 100px;
	}

	// @include media('>tablet') {
	// 	margin-bottom: 1.8vw;
	// }

	@include media('<tablet') {
		margin-bottom: 48px;
	}

	&.paused {
		& > .welcome__video__bg {
			background: rgba(0, 0, 0, 0.2);
			cursor: pointer;
		}
		& > .welcome__video__status {
			opacity: 1;
		}
	}
}
.welcome__video__status {
	@extend %light-16;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: z('content-front');
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}
.welcome__video__bg {
	width: 100%;
	height: 100%;
	border-radius: inherit;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0);
	z-index: z('content');
	transition: background 0.3s ease-in-out;
}
.welcome__video {
	width: 100%;
	object-fit: cover;
	object-position: bottom;
	max-height: inherit;
	border-radius: inherit;

	z-index: z('content-back');
	@include media('<tablet') {
		border-radius: 0;
	}
}

.welcome__text__block {
	display: grid;

	@include media('>laptop') {
		gap: 100px;
	}

	@include media('>tablet') {
		grid-template-columns: repeat(3, 1fr);
		gap: 60px;
	}

	@include media('<tablet') {
		display: flex;
		flex-direction: column;
		grid-template-rows: repeat(3, 1fr);
		gap: 0;
	}
}

.welcome__text__element {
	@extend %paragraph-16;
	text-align: left;

	@include media('<tablet') {
		max-width: 525px;
	}
}
