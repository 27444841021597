$contact-max-width: 600px;
$contact-form-max-width: 600px;
$contact-color: rgb(var(--data-color-black));

.contact {
	@extend %center;
	padding: 0 0;
	margin: 0 0;
	margin-top: 20vh;
}
.contact__wrapper {
	@extend %center;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: inherit;
	height: inherit;
	min-height: inherit;
	padding: 0 0;
	margin: 0 0;

	@include media('<tablet') {
		display: flex;
		flex-direction: column;
	}
}
.contact__left {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 0;

	@include media('>tablet') {
		min-height: inherit;
		border-radius: 0 8px 8px 0;
	}
}

.contact__left__img {
	transform: scale(1.1);
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: inherit;
	min-height: inherit;
}
.contact__right {
	@extend %center;
	// align-items: flex-start;
	position: relative;
	flex-direction: column;

	height: 100%;
	min-height: inherit;
	padding: 5vh 24px;

	@include media('>phone') {
		width: 99%;
		// padding-left: 10vw;
	}
	@include media('<phone') {
		width: 100%;
		padding: 5vh 0;
		padding-top: 28px;

		.contact__title,
		.contact__line,
		.contact__paragraph,
		.contact__form__comment {
			padding: 0 24px;
			max-width: $contact-max-width;
		}
	}
}
.contact__title {
	font-size: 48px;
	font-weight: 700;
	text-align: left;
	width: 100%;
	max-width: $contact-max-width;

	@include media('<phone') {
		max-width: 100%;
	}
}

.contact__line {
	background: $contact-color;
	height: 1px;
	width: 100%;
	max-width: $contact-max-width;

	margin-bottom: 40px;
	margin-top: 24px;
	transform-origin: left;

	@include media('<phone') {
		max-width: 100%;
		margin-top: 32px;
		margin-bottom: 50px;
	}
}

.contact__paragraph {
	@extend %paragraph-16;
	max-width: $contact-max-width;
	text-align: justify;

	@include media('<phone') {
		max-width: 100%;
	}
}
.contact__form__container {
}
.contact__form__comment {
	@extend %center;
	@extend %light-12;

	margin-top: 42px;
	margin-bottom: 16px;
	color: $contact-color;

	@include media('<phone') {
		margin-top: 20px;
	}
}
.contact__form__comment__svg {
	&--left {
		margin-right: 8px;
	}

	&--right {
		margin-left: 8px;
	}

	& path {
		fill: $contact-color;
	}
}

.contact__form {
}
