.circle__button {
	@extend %center;

	position: absolute;

	right: 0;
	bottom: 0;
	height: 96px;
	width: 96px;
	user-select: none;
	margin-bottom: 18px;
	margin-right: 5vw;
	z-index: z('content-front');
	cursor: pointer;
	// padding-bottom: env(safe-area-inset-bottom);

	@include media('<phone') {
		margin-right: 18px;
	}
}

.circle__wrapper {
	@extend %center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	background-color: rgb(var(--data-color-black));

	opacity: 0.9;
	border-radius: 12px;

	& svg {
		width: 42px;
		height: 42px;
	}
}

.circle__button__text {
	@extend %link--hidden;
	position: absolute;
	left: 50%;
	bottom: 24%;
	transform: translate(-50%, 50%);
	font-size: 14px;
	height: 16px;

	@include media('<laptop') {
		font-size: 14px;
	}

	@include media('<phone') {
		visibility: hidden;
		opacity: 0;
	}
}

.circle__button__text--inner {
}
.circle__button__text.active:after {
	transform: scaleX(1);
	transform-origin: left center;
}
