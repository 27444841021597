nav {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: z('navigation');
	padding: 0 0;

	background: transparent;

	// &.nav--cover {
	// 	background: linear-gradient(
	// 		to bottom,
	// 		rgba(var(--data-color-background), 0.8),
	// 		rgba(var(--data-color-background), 0)
	// 	);
	// }
	//test
	transform: translateY(0%);

	@include media('<phone') {
		background: rgb(var(--data-color-background));
		padding: 0 0;
		position: sticky;
	}
}

.nav__wrapper {
	@extend %center;

	width: 100%;
	min-height: 110px;
	padding: 0 5vw;
	z-index: 10;
	position: relative;
	user-select: none;

	@include media('<laptop') {
		min-height: 0rem;

	}

	@include media('<phone') {
		padding: 0 16px;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 100px -41px,
		rgba(0, 0, 0, 0.3) 0px 10px 80px -32px;
	}
}
.nav__left {
	width: 20%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
}
.nav__logo__img {
}
.nav__logo {
	position: relative;
	width: 100%;
	max-width: 96px;
	left: -24px;
	transform: translateY(0);

	@include media('<laptop') {
		max-width: 74px;
		left: -16px;
	}

	& path {
		fill: rgb(var(--data-color-black));
	}
}

.nav__logo--overlay {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;

	transition: opacity 0.25s ease;
	transition-delay: 0.1s;
	// animation: reveal 2s forwards infinite;
	// animation-delay: 1s;
	// clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}
.nav__right {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
	width: 80%;
}

.nav__email__item {
	display: flex;
	flex-direction: row;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	margin-right: 15%;

	&.open {
		visibility: visible;
		opacity: 1;
	}

	@include media('<laptop') {
		font-size: 12px !important;
		line-height: 16px;
	}
}
.nav__email__item-left {
	margin-right: 16px;
	height: 24px;

	& svg path {
		fill: rgb(var(--data-color-stone));
		stroke: rgb(var(--data-color-stone));
		stroke-dasharray: 100; /* Adjust based on path length */
		stroke-dashoffset: 0;
		transition: fill 0.3s ease;
	}

	&--email {
		svg path {
			stroke-dashoffset: 100;
		}
	}

	&:hover svg path {
		fill: none;
		stroke: rgb(var(--data-color-stone));
		animation: draw-stroke 1.8s ease forwards;
	}

	@include media('<phone') {
		&.nav__email__item-left--email {
			display: none;
		}
	}
}

@keyframes draw-stroke {
	from {
		stroke-dashoffset: 100;
	}
	to {
		stroke-dashoffset: 0;
	}
}
.nav__email__item-right {
	@extend %paragraph-16;
	display: flex;
	flex-direction: column;
	color: rgb(var(--data-color-stone));
	line-height: 22px;
	margin-bottom: 0;
}
@keyframes slideGradient {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

.nav__email__item--row {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 0;
	font-size: 16px;
	color: rgb(103, 103, 103);

	& .nav__email__title {
		margin-right: 8px;
	}
	& a {
		color: rgb(103, 103, 103);
		background: linear-gradient(
			90deg,
			rgb(103, 103, 103),
			rgba(255, 255, 255, 0.7),
			rgb(103, 103, 103)
		);
		background-size: 200% auto;
		animation: slideGradient 3s infinite linear;
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
	}
}

.nav__email__title {
	font-weight: bold;
}
.nav__email__link {
	@extend %link--hidden;

	&:hover {
		color: rgba(var(--data-color-black), 0.8);
	}

	&.dark:hover {
		color: rgb(var(--data-color-stone)) !important;
	}
}

// .nav__menu {
// 	width: 48px;
// 	height: 48px;
// 	background: grey;
// }

.nav__logo__svg--main {
	display: flex;
	align-items: flex-end;

	&.dark {
		&:hover {
			path {
				fill: rgb(var(--data-color-stone)) !important;
			}
		}
	}
}
.nav__lang-switch {
	position: relative;
	width: 48px;
	height: 48px;
	min-width: 48px;
	margin-right: 8px;
	transform: translateY(0);
}
.nav__lang {
	@extend %center;
	width: 48px;
	height: 48px;
	min-width: 48px;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	border-radius: 8px;
	background: rgb(var(--data-color-black));
	transition: background 0.3s;
	transition-timing-function: ease-in;
	opacity: 0.9;
}
.nav__lang-current {
	z-index: 2;
}
.nav__lang-other {
	visibility: hidden;
	z-index: 1;
	transform: translateY(100%);
}
.nav__menu {
	width: 48px;
	height: 48px;
	min-width: 48px;
	position: relative;
	cursor: pointer;
	border-radius: 8px;
	background: rgb(var(--data-color-black));
	opacity: 0.9;
	transition: background 0.3s;
	transition-timing-function: ease-in;
	transform: translateY(0);

	.nav__menu__line {
		width: 50%; // middle line is shorter
		height: 2px;
		position: absolute;
		left: 50%;
		background-color: rgb(var(--data-color-white));
		transition: background-color 0.3s;
		transition-timing-function: ease-in;
		transform: translateX(-50%);

		&:first-child {
			top: 15px; // 15px from the top
		}

		&:nth-child(2) {
			left: 26px;
			top: 23px; // 7px distance between lines
			width: 40%; // this line is full length
		}

		&:last-child {
			top: 31px; // 15px from the bottom
		}
	}

	@include media('<phone') {
		margin: 0 0 !important;
	}
}

.nav__menu.open {
	background: rgb(var(--data-color-white));
}

.nav__menu:hover,
.nav__lang:hover,
.nav__lang.active {
	background-color: rgb(var(--data-color-stone));
	transition-timing-function: ease-out;
}
.nav__menu:hover > .nav__menu__line {
	background-color: rgb(var(--data-color-stone-black)) !important;
	transition-timing-function: ease-out;
}

// .nav__menu div:nth-child(2) {
// 	width: 60%;
// }
.nav__content {
	visibility: hidden;
	width: 100%;

	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;

	// background: rgb(var(--data-color-black));
	background: rgb(var(--data-color-black));
	color: rgb(var(--data-color-white));
	user-select: none;
	z-index: 7;

	@include media('<tablet') {
		overflow-y: scroll;
	}
}

.nav__content__wrapper {
	position: relative;
	height: 100%;
	width: 100%;
	padding: 0 5vw;

	@include media('<phone') {
		padding: 0 5vw;
	}
}

.nav__content__general-container {
	@extend %center;
	width: inherit;
	height: inherit;

	@include media('<laptop') {
		flex-direction: row-reverse;
	}
}
.nav__content__image-container {
	position: relative;

	width: 45%;

	svg {
		path {
			fill: rgb(var(--data-color-stone));
		}

		// @include media('>tablet') {
		// 	path:hover {
		// 		fill: rgb(var(--data-color-stone));
		// 	}
		// }
	}

	@include media('>laptop') {
		left: -2%;
	}
	@include media('>tablet', '<laptop') {
		right: -3%;
	}

	@include media('<tablet') {
		display: none;
	}
}
.nav__content__link-container {
	@extend %center;
	align-items: flex-end;
	text-align: right;
	flex-direction: column;
	width: inherit;
	height: inherit;

	@include media('<laptop') {
		align-items: flex-start;
		text-align: left;
	}
	@include media('<phone') {
		padding-bottom: calc(44px + env(safe-area-inset-bottom));
	}
	@include media('<400px') {
		justify-content: flex-start;
		padding-top: 96px;
	}
}
.nav__content__link {
	@extend %bold-62;

	text-align: inherit;
	position: relative;
	overflow: hidden;
	width: 370px;
	height: 64px;
	margin: 40px 0;

	@include media('<laptop') {
		margin: 20px 0;
	}

	@include media('<phone') {
		width: 100%;
		left: -2px;
	}
	// less than 400px
	@include media('<400px') {
		font-size: 32px;
		margin: 16px 0;
		height: 40px;
	}
	color: rgb(var(--data-color-white));
}
.nav__content__link--inner {
	position: absolute;
	top: 2px;
	left: 0;
	height: inherit;
	color: #676767;
	width: 100%;
}

.nav__content__link--inner:hover {
	color: rgb(var(--data-color-stone));
}

@keyframes gradient {
	0% {
		background-position: 0%;
	}
	100% {
		background-position: 100%;
	}
}

.nav__content__link--inner.active {
	color: rgb(var(--data-color-stone));
	pointer-events: none;

	// -webkit-text-fill-color: rgb(var(--data-color-black));
	// -webkit-text-stroke-width: 1px;
	// -webkit-text-stroke-color: rgb(var(--data-color-white));
}
