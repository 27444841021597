.toggle__switch {
	@extend %center;
	flex-direction: column;
	font-weight: 600;
	max-width: 100%;
	position: relative;

	color: #272833;
	font-size: 0.875rem;
	font-weight: 600;
	margin-bottom: 0.25rem;
	max-width: 100%;
	word-wrap: break-word;

	cursor: pointer;
}
.toggle__switch__title {
	margin-bottom: 8px;
	font-size: 12px;
}
.toggle__switch__check {
	bottom: 0;
	font-size: 62.5%;
	height: 32px;
	opacity: 0;
	position: absolute;
	width: 60px;
	z-index: 2;

	box-sizing: border-box;
	padding: 0;

	cursor: pointer;
}

.toggle__switch__check:empty ~ .toggle__switch__bar {
	display: inline-flex;
	font-size: 0.75rem;
	height: 32px;
	line-height: 32px;
	position: relative;
	text-indent: 0;
	user-select: none;
}

.toggle__switch__check:empty ~ .toggle__switch__bar::before {
	background-color: #a7a9bc;
	border-color: #a7a9bc;
	border-radius: 20px;
	border-style: solid;
	border-width: 1px;
	bottom: 0;
	content: ' ';
	display: block;
	left: 0;
	position: absolute;
	top: 0;
	transition: background-color 100ms ease-in, border-color 100ms ease-in,
		box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in,
		right 100ms ease-in;
	width: 60px;
}
.toggle__switch__check:checked ~ .toggle__switch__bar::before {
	background-color: #64d8a0;
	border-color: #64d8a0;
	border-radius: 20px;
	border-style: solid;
	border-width: 1px;
}

.toggle__switch__check:empty ~ .toggle__switch__bar::after {
	background-color: #fff;
	border-color: #fff;
	border-radius: 50%;
	border-style: solid;
	border-width: 1px;
	bottom: 4px;
	content: '';
	display: block;
	height: 24px;
	left: 4px;
	position: absolute;
	top: 4px;
	transition: background-color 100ms ease-in, border-color 100ms ease-in,
		box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in,
		right 100ms ease-in;
	width: 24px;
}
.toggle__switch__check:checked ~ .toggle__switch__bar::after {
	background-color: #fff;
	border-color: #fff;
	border-radius: 50%;
	border-style: solid;
	border-width: 1px;
	left: 32px;
}
.toggle__switch__bar .toggle__switch__handle {
	display: block;
	min-width: 60px;
	text-transform: uppercase;
}

.toggle__switch__icon {
	color: #fff;
	left: 4px;
	line-height: 24px;
	position: absolute;
	text-align: center;
	text-indent: 0;
	top: 4px;
	transition: background-color 100ms ease-in, border-color 100ms ease-in,
		box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in,
		right 100ms ease-in;
	width: 24px;
	z-index: 1;
}
.toggle__switch__icon .toggle__switch__icon--on {
}
