.about {
	@extend %page;
	@extend %center;

	background: rgb(var(--data-color-background));

	@include media('<phone') {
		flex-direction: column-reverse;
		min-height: fit-content;
	}
}
.about__container {
	@extend %center;

	flex: 1;
	flex-direction: column;

	width: 50%;
	height: fit-content;
	min-height: inherit;

	padding: 0 7vw;
	box-sizing: border-box;
	@include media('<laptop') {
		// justify-content: flex-start;
		padding: 0 3vw;
	}
	@include media('<phone') {
		width: 100%;
		padding: 0 0;
	}
}
.about__left {
}
.about__text {
	@extend %paragraph-20;
	text-align: justify;

	@include media('<phone') {
		width: 100%;
		text-align: justify;
	}
}

.about__right {
}

.about__top__title {
	@extend %bold-62;

	width: 100%;
	text-align: right;
}
.about__image__figure {
	margin: 64px 0;

	width: 100%;
	height: auto;

	aspect-ratio: 1;
	border-radius: 2px;
	overflow: hidden;
	position: relative;

	@include media('<laptop') {
		margin: 24px 0;
	}
}

.about__image {
	width: 100%;
	border-radius: inherit;

	transform: scale(1.1);
	top: 0;
	left: 0;
	position: absolute;

	@include media('<phone') {
		transform: scale(1.5);
	}
}

.about__bottom__titles {
	width: 100%;

	& h4 {
		@extend %bold-48;
	}
	@include media('<phone') {
		margin-bottom: 62px;
	}
}

.about__bottom__titles--right {
	@include media('<laptop') {
		text-align: left;
	}
	@include media('<phone') {
		text-align: right;
	}
	text-align: right;
}

.about__bottom__titles--left {
	text-align: left;
}
