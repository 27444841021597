.cover__nav {
	@extend %center;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: z('content');

	&:hover,
	&.is-locked {
		.cover__nav-elements {
			opacity: 0.9;
			transform: translateY(0);
			transition-timing-function: $ease-out-expo;
		}
	}

	&.disabled {
		cursor: default;

		* {
			pointer-events: none;
		}
	}
}
.cover__nav-showbtn {
}
.cover__nav-elements__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	@include media('<phone') {
		align-items: flex-start;
		margin-left: 18px;
	}
}
.cover__nav-elements {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 18px;
	width: fit-content;
	position: relative;
	border-radius: 12px;
	overflow: hidden;

	opacity: 0;
	transform: translateY(200%);
	transition: transform 0.7s, opacity 0.7s;
	transition-timing-function: $ease-in-expo;
}
.cover__nav-elements--buttons {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	// gap: 2px;
}

.cover__nav__btn--play {
	&.is-playing {
		.cover__nav__play-icon {
			visibility: hidden;
		}
		.cover__nav__pause-icon {
			visibility: visible;
		}
	}
}

.cover__nav__btn {
	position: relative;
	width: 48px;
	height: 48px;
	background: rgb(var(--data-color-black));
	// border-radius: 10px;
	cursor: pointer;
	transition: background-color 0.3s;
	transition-timing-function: ease-in;

	@include media('>tablet') {
		&:hover {
			background-color: rgb(var(--data-color-stone));
			transition-timing-function: ease-out;
		}
	}

	&.active {
		background-color: rgb(var(--data-color-stone));
	}
}

.cover__nav__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.cover__nav__play-icon {
	position: absolute;
	top: 50%;
	left: 53%;
	transform: translate(-50%, -50%);
}
.cover__nav__pause-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	visibility: hidden;
}
.cover__nav__text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 12px;
	font-weight: 500;
	color: rgb(var(--data-color-white));
	user-select: none;
}

//
.cover__nav-elements--info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 248px;
	// margin-top: 2px;

	&.is-visible {
		display: flex;
	}
}
.cover__nav__info {
	display: flex;
	justify-content: space-between;
	align-items: center;

	background-color: rgb(var(--data-color-black));
	// border-radius: 10px;
	height: 48px;
	width: 100%;
}
.cover__nav__info__title {
	font-size: 12px;
	font-weight: 500;
	color: rgb(var(--data-color-white));
	padding: 0 14px;
}
.cover__nav__btn--lock {
	&.active {
		background-color: rgb(var(--data-color-stone));
	}
}
.cover__nav__info--box1 {
	overflow: hidden;
}
