.siteby {
	display: flex;
	flex-direction: column;
	position: relative;
	bottom: 0;
	right: 0;
	// margin-right: 18px;
	// margin-bottom: 8px;

	& span {
		@extend %light-12;
		display: inline-block;
		min-height: 20px;
		color: #706f6d;
	}
	& a {
		// @extend %light-12;
		@extend %link--hidden;
		height: 20px;

		color: #111;
	}

	@include media('<tablet') {
		left: 0;
		margin-right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
