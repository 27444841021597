.webgl {
	z-index: z('canvas');
	position: fixed;
	top: 0;
	left: 0;
	outline: none;
	opacity: 0;

	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}
