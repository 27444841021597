.contact__form__container {
	width: 100%;
	max-width: $contact-form-max-width;
	
	@include media('<phone') {
		max-width: 100%;
		width: 100%;
	}
}

.contact__form {
	border-radius: 20px;
	max-width: $contact-form-max-width;
	margin: auto;
	border: 1px solid rgb(var(--data-color-stone));

	@include media('<phone') {
		max-width: 100%;
		width: 100%;
	}

	@include media('<phone') {
		border-radius: 0;
		border: none;
	}
}


.contact__form__wrapper {
	display: grid;
	justify-items: center;
	// grid-template-columns: repeat(2, 1fr);
	grid-template-areas: 'firstName lastName' 'email .' 'subject type';
	gap: 52px;
	padding: 52px 40px;

	@include media('<phone') {
		padding: 8px 24px;
		padding-top: 24px;
	}

	@include media('>phone') {
		width: 100%;
	}
}

.contact__form__group {
	position: relative;
	width: 100%;

	& input {
		@extend %paragraph-16;

		width: inherit;
		border: none;
		border-bottom: 1px solid rgb(var(--data-color-stone));
		padding: 8px 12px;
		background: none;
		height: 100%;
		max-height: 62px;

		&:focus{
			outline: none;
		}

		@include media('<phone') {
			font-size: 12px;
		}
	}
	& textarea {
		@extend %paragraph-16;

		width: inherit;
		border: none;
		border-bottom: 1px solid rgb(var(--data-color-stone));

		min-height: 7.7rem;
		padding-top: 3rem;
		resize: vertical;

		&:focus{
			outline: none;
		}

		@include media('<phone') {
			font-size: 12px;
		}
	}

	&:nth-child(1) {
		grid-area: firstName;
	}
	&:nth-child(2) {
		grid-area: lastName;
	}
	&:nth-child(3) {
		grid-area: email;
	}
	// &:nth-child(4) {
	// 	grid-area: subject;
	// }
	// &:nth-child(5) {
	// 	grid-area: type;
	// }
}

.contact__form__group--select {
	border: none;
	border-bottom: 1px solid rgb(var(--data-color-stone));
	padding: 8px 0;
	background: none;
	width: 100%;
	height: 100%;
	max-height: 62px;
	position: relative;

	&:nth-child(4) {
		grid-area: type;
	}
	&:nth-child(5) {
		grid-area: subject;
	}
	& select {
		@extend %paragraph-16;
		color: rgb(var(--data-color-stone));

		border: none;
		background: none;

		width: 90%;
		height: 100%;

		position: relative;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);

		@include media('<phone') {
			font-size: 12px;
		}
	}

	& option {
		@extend %paragraph-16;
		color: $contact-color;
		border: 1px solid rgb(var(--data-color-stone));
		border-radius: 8px;
		background: none;
		padding: 8px 0;

		@include media('<phone') {
			font-size: 12px;
		}
	}
}
.contact__form__group label,
.contact__form__group--select summary label {
	@extend %paragraph-16;
	font-size: 14px;
	pointer-events: none;
	color: $contact-color;
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 1;
	transition: opacity 0.3s;
	margin-bottom: 0;

	@include media('<phone') {
		font-size: 12px;
	}
}

.contact__form__group--select {
	& ul {
		background: var(--window-bg);
		border: 1px solid #ced4da;
		border-radius: 5px;
		box-shadow: 0 0 6px rgb(0 0 0/3%);
		box-sizing: border-box;
		left: 0;
		list-style: none;
		margin: 0;
		max-height: 200px;
		overflow-y: auto;
		padding: 1rem;
		position: absolute;
		top: calc(100% + 0.5rem);
		width: 108%;
	}

	& li {
		border-bottom: 1px solid #ced4da;
		margin: 0;
		padding: 1rem 0;
	}

	& label {
		// @extend %paragraph-16;
		cursor: pointer;
		display: flex;

		justify-content: space-between;
		width: 100%;
	}
}

.contact__form__group__svgarrow {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%) rotate(0deg);
	transform-origin: center;
}

details[open] {
	cursor: default;
}
details[open] .contact__form__group__svgarrow {
	transform: translateY(-50%) rotate(180deg);
}
details[open] summary:before {
	background: transparent;
	content: '';
	display: block;
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
}

summary::-webkit-details-marker {
	display: none;
}

.contact__select__radios {
	counter-reset: radios;
	list-style: none; // removes marker

	::marker {
		opacity: 0;
	}
	&:focus {
		outline: none;
	}

	input {
		@extend %paragraph-16;
		color: $contact-color;
		pointer-events: none;
		// position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		opacity: 1;
		transition: opacity 0.3s;

		@include media('<phone') {
			font-size: 12px;
		}
	}

	input[type='radio']:checked {
		--display: block;
		display: inline;
		text-wrap: nowrap;
	}
	& input[type='radio']:after {
		font-family: $font-manrope;
		content: attr(value);
		display: inline;

		@extend %paragraph-16;

		pointer-events: none;
		color: $contact-color;
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		opacity: 1;
		transition: opacity 0.3s;

		@include media('<phone') {
			font-size: 12px;
		}
	}
}

.contact__select__details {
	cursor: pointer;

	.list {
		background: rgb(var(--data-color-white));
		border: 1px solid rgb(var(--data-color-stone));
		border-radius: 5px;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.03);
		box-sizing: border-box;
		left: 0;
		list-style: none;
		margin: 0;
		max-height: 340px;
		overflow-y: auto;
		padding: 0;
		position: absolute;
		top: calc(100% + 0.5rem);
		width: 180%;
		z-index: z('content-front');

		& li {
			border-bottom: 1px solid rgb(var(--data-color-stone));
			padding: 0;

			&:last-child {
				border-bottom: none;
			}

			& label {
				@extend %paragraph-16;
				color: $contact-color;
				background: transparent;
				opacity: 0.74;
				cursor: pointer;
				display: flex;
				justify-content: flex-start;
				width: 100%;
				height: 100%;
				padding: 24px 16px;
				margin: 0 0;
				// transition: all 0.5s ease-in-out;

				&:hover {
					opacity: 1;
					background: rgba(var(--data-color-stone), 0.25);
					color: rgb(var(--data-color-black));
					font-weight: bold;
				}
			}
		}

		& li:first-child {
			padding-top: 0;
		}
	}

	@include media('<phone') {
		.list {
			width: 100%;
		}
	}
}

.contact__form__group--select {
	& input[type='radio'] {
		appearance: none;
		counter-increment: radios;
		display: none;

		::after {
			content: attr(title);
			display: inline;
			font-family: $font-manrope;
			// font-size: 1rem;
		}
	}
}

.contact__form__group__textarea {
	grid-column: span 2;
}

.contact__form__group__textarea label {
	top: 3.2rem;
	transform: translateY(0%);
}

/* active state */

.contact__form__group input:focus ~ label,
.contact__form__group textarea:focus ~ label {
	opacity: 0;
}

.contact__form__group--filled label {
	opacity: 0;
}
.contact__form__button__wrapper {
	overflow: hidden;
	border-radius: 0 0 20px 20px;
	margin: 0 0;
	margin-top: 72px;

	@include media('<phone') {
		border-radius: 0;
	}
}
.contact__form__button {
	@extend %paragraph-24;
	@extend %center;

	justify-content: space-around;

	padding: 20px;
	border-radius: 0;
	// border-radius: 0 0 20px 20px;
	margin: 0 0;
	width: 100%;
	max-width: 100%;

	background: rgb(var(--data-color-black));
	color: rgb(var(--data-color-white));

	cursor: pointer;

	overflow: hidden;
	transform-origin: left;

	& p {
		// font-size: inherit;
		font-weight: 400;
	}
	// transition: all 0.1s cubic-bezier(0.42, 0, 0.58, 1);
}

.contact__form__button__text {
	// @extend %link--hidden;

	position: relative;
	// transform: scale(1) translateY(0%);

	user-select: none;

	// &.active:after {
	// 	transform: scaleX(1);
	// 	transform-origin: left center;
	// }
}

.contact__form__button__text p {
	// transition: all 0.49s cubic-bezier(0.42, 0, 0.58, 1);
}

.contact__form__button__text p:first-of-type {
	transform-origin: right;
}

.contact__form__button__text--clone {
	position: absolute;
	top: 0;
	left: 0;
	transform: scale(0.8) translateY(-400%);
	font-style: italic;
	transform-origin: center;
}

.contact__form__button__icon--loading {
	@extend %hidden;

	position: absolute;
	top: 0;
	left: 0;
}
.contact__form__button__icon--arrow {
	visibility: visible;
	opacity: 1;
	position: absolute;
	top: 0;
	left: 0;
}
.contact__form__button__icon--checkmark {
	@extend %hidden;

	position: absolute;
	top: 0;
	left: 0;
}
.contact__form__button__icon--cross {
	@extend %hidden;

	position: absolute;
	top: 0;
	left: 0;
}
.contact__form__button__icon {
	position: relative;
	display: inline-block;
	width: 28px;
	height: 28px;

	&.delivered path {
		fill: rgb(var(--data-color-white));
	}
}
.contact__form__button__icon > div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 28px;
	height: 28px;
	border: 3px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.contact__form__button__icon div:nth-child(1) {
	animation-delay: -0.45s;
}
.contact__form__button__icon div:nth-child(2) {
	animation-delay: -0.3s;
}
.contact__form__button__icon div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.contact__form__button:hover,
.contact__form__button.active {
	border-inline-color: grey;
	background: rgb(var(--data-color-cyan));
}

.contact__form__button.error {
	color: rgb(var(--data-color-white));
	background: #d86464;
}

.contact__form__button.delivered {
	background: rgb(var(--data-color-cyan));
	color: rgb(var(--data-color-white));

	& .contact__form__button__text:after {
		transform: scaleX(0);
	}
}
// .contact__form__button:active {
// 	background: hsl(0, 0%, 20%);
// }

// Text animation
// .contact__form__button:hover > .contact__form__button__text p:first-of-type {
// 	transform: scale(0.8) translateY(400%);
// }

// .contact__form__button:hover
// 	> .contact__form__button__text
// 	.contact__form__button__text--clone {
// 	transform: scale(1) translateY(0%);
// }

// Test
