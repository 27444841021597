main {
	font-size: 3rem;
	width: 100%;
	height: auto;
	position: relative;
}

.cover__container {
}
.cover {
	opacity: 1;
	visibility: visible;

	width: 100%;

	height: 100vh;

	background-position: left;
	background-size: cover;
	padding: 0 0;

	color: rgb(var(--data-color-white));
	z-index: z('content');
}

.cover__wrapper {
	display: flex;
	flex-direction: column;

	opacity: 1;
	visibility: visible;

	width: 100%;
	height: 100%;
	max-width: 1920px;
	margin: auto;

	position: relative;
	z-index: z('content');
}

.cover__block {
	display: flex;
	// justify-content: space-between;
	justify-content: flex-end;
	margin: 0 10vh;

	@include media('<laptop') {
		margin: 0 5vh;
	}

	@include media('<tablet') {
		margin: 0 24px;
	}

	@include media('<phone') {
		margin: 0 12px;
	}
}

.cover__block__left,
.cover__block__right {
	display: flex;
	flex-direction: column;
}

.cover__item {
	padding: 120px;

	@include media('<laptop') {
		padding: 5vh;
	}

	@include media('<tablet') {
		padding: 24px;
	}

	@include media('<phone') {
		padding: 6px;
	}
}

// .cover__block__left {
// 	& .cover__item {
// 		@include media('<phone') {
// 			padding-right: 6px;
// 		}
// 	}
// }
// .cover__block__right {
// 	& .cover__item {
// 		@include media('<phone') {
// 			padding-left: 6px;
// 		}
// 	}
// }
.cover__figure {
	&.square,
	&.vertical,
	&.landscape {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	&.square {
		height: 200px; /* adjust to control the height of square images */
	}
	&.vertical {
		height: 400px; /* adjust to control the height of vertical images */
	}
	&.landscape {
		width: 100%;
		height: auto;
	}
}

.cover__item {
}
.cover__image {
	// border-radius: inherit;
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.cover__logo {
	position: absolute;
	top: 1.75rem;
	left: 6.5rem;
	max-width: 12.8rem;
	max-height: 12.8rem;

	@include media('<tablet') {
		display: none;
	}
}
.cover__ui__mobile--extra {
	display: none;

	@include media('<phone') {
		display: block;
		font-size: 16px;
		width: fit-content;
		height: 22px;
		margin: 0;
	}
}
.cover__ui__bottom {
	user-select: none;
	min-height: 6.4rem;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	z-index: z('content');

	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 80px 5vw;
	color: rgb(var(--data-color-black));
	background: linear-gradient(
		to top,
		rgba(var(--data-color-background), 0.8),
		rgba(var(--data-color-background), 0)
	);

	&.white,
	&.white .cover__ui__bottom__right__links a {
		// color: rgb(var(--data-color-white));
		color: rgb(var(--data-color-stone));
		background: none;
	}

	&.white .cover__ui__bottom__right__links a:hover {
		color: rgb(var(--data-color-stone));
	}

	@include media('<laptop') {
		flex-direction: column;
		align-items: flex-start;
	}

	@include media('<phone') {
		padding: 0 5vw;
	}
}
.cover__ui__bottom__left {
	display: flex;
	flex-direction: column-reverse;

	& h1 {
		@extend %black-256;
		font-family: $font-kumbh-sans;
		font-size: 42px;
		// line-height: 33px;
	}

	& h2 {
		@extend %paragraph-16;

		margin-bottom: 0;
	}

	@include media('<laptop') {
		margin-bottom: 24px;
	}

	@include media('<phone') {
		display: none;
	}
}

.cover__ui__bottom__right {
	@include media('<phone') {
		display: none;
	}
}

.cover__ui__bottom__right__links {
	display: flex;
	@extend %paragraph-16;

	margin-bottom: 0;

	& a {
		@extend %link--hidden;
		
		margin-right: 60px;
		color: rgb(var(--data-color-black));

		&:hover {
			color: rgb(98, 98, 98);
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
.cover__ui__bottom__wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: calc(80px + env(safe-area-inset-bottom));
	// position: fixed;
	// bottom: 0;
	// padding-bottom: env(safe-area-inset-bottom);

	@include media('>phone') {
		display: none !important;
	}
}
.cover__ui__bottom--mobileonly {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-top: 40px;

	a {
		height: 36px;
		width: auto;

		margin-left: 32px;

		&:first-child {
			margin-left: 0;
		}

		&:hover > svg path {
			fill: rgb(var(--data-color-white));
		}
	}

	svg {
		width: inherit;
		height: inherit;
	}
}
.cover__button {
	opacity: 0;
}
