.lightbox {
	@extend %center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: z('popup');

	&--hidden {
		@extend %hidden;
	}
}

.lightbox__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background: rgba(var(--data-color-black), 0.9);
	z-index: z('content-back');
	// backdrop-filter: blur(20px);
}

.lightbox__content {
	position: relative;
	width: 100vw;
	height: auto;
	z-index: z('content');
}

.lightbox__cross {
	@extend %center;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	z-index: z('navigation');
	width: 64px;
	height: 64px;
	border-radius: 2px;
	background: rgba(var(--data-color-white), 0);

	svg {
		opacity: 0.87;
		transition: opacity 0.2s ease-in-out;
		path {
			stroke: rgb(var(--data-color-white));
		}
	}

	@include media('>tablet') {
		&:hover {
			background: rgba(var(--data-color-white), 0.12);
			svg {
				opacity: 1;
			}
		}
	}

	@include media('<tablet') {
		top: 8px;
		left: 8px;
		width: 48px;
		height: 48px;
		background: rgb(var(--data-color-black));
		border-radius: 12px;
	}
}

.lightbox__figure-wrapper {
	@extend %center;
	position: relative;
	width: 100%;
	height: 100%;
}

.lightbox__figure {
	@extend %center;
	position: relative;
	min-width: 100vw;
	width: 100%;
	height: 100%;
}
.lightbox__inner {
	@extend %center;
	flex-direction: column;
	border-radius: inherit;
	height: 100%;
	width: 100%;
	@include media('<tablet') {
		padding: 20px;
	}
	// min-width: inherit;
}
.lightbox__texts {
	position: absolute;
	z-index: z('navigation');
	height: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: flex-end;
	min-height: 56px;
	padding-bottom: 18px;
	width: 100%;
	// background: rgb(var(--data-color-darkgrey));

	@include media('<phone') {
		// padding: 7px 14px;
		padding: 0;

		padding-bottom: 12px;
	}
}

.lightbox__counter {
	display: flex;
	justify-content: flex-end;
}
.lightbox__counter-wrapper {
	min-width: 10%;
}
.lightbox__title,
.lightbox__subtitle,
.lightbox__counter-text {
	color: rgb(var(--data-color-white));

	font-size: 16px;
	margin-top: 5px;
	width: 100%;
	text-align: right;
	@include media('<tablet') {
		// text-align: center;
		font-size: 14px;
	}
}
.lightbox__counter-text {
	justify-self: flex-end;
}
.lightbox__counter-text--inner {
	display: block;
	text-align: center;
	width: 64px;
	float: right;
}
.lightbox__title-wrapper {
	display: flex;
	flex-direction: column;
	min-width: 61.8%;
	padding-left: 20px;

	* {
		text-align: left;
	}
}

.lightbox__image,
.lightbox__video {
	border-radius: inherit;
	// max-width: 80%;
	max-height: 80vh;
	// width: auto;
	height: 100%;
	object-fit: contain;

	// @include media('>tablet') {
	// 	box-sizing: $shadow-large;
	// }

	@include media('>tablet') {
		padding: 48px 0;
	}
}

.lightbox__image--hidden,
.lightbox__video--hidden {
	display: none;
}

.lightbox__arrow {
	@extend %center;
	position: absolute;

	top: 50%;
	transform: translateY(-50%);

	height: 100%;
	width: 64px;

	z-index: z('content-front');
	cursor: pointer;
	background: rgba(var(--data-color-white), 0);
	opacity: 0;

	svg {
		opacity: 0.87;
		transition: opacity 0.2s ease-in-out;

		path {
			fill: rgb(var(--data-color-white));
		}
	}
	@include media('>tablet') {
		&:hover {
			background: rgba(var(--data-color-white), 0.12);
			svg {
				opacity: 1;
			}
		}
	}

	@include media('<tablet') {
		svg {
			background: rgb(var(--data-color-black));
			// border-radius: 50%;
			border-radius: 12px;
		}
	}
}

.lightbox__arrow--left {
	left: 0;
}

.lightbox__arrow--right {
	right: 0;
}
