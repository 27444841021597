// General
.expertise {
	display: flex;
	flex-direction: column;

	min-height: 100vh;
	background: rgb(var(--data-color-background));
	margin-top: 20vh;
	padding-bottom: 0;
	padding-top: 0;
}

.expertise .section__title {
	@extend %center;
	margin-left: 0;
	text-align: center;
	margin-bottom: -76px;
	position: absolute;
	top: 0;
	left: 0;
}
.expertise .section__wrapper {
	@extend %center;
	margin-bottom: 0;
	// margin-top: 10vh;
	max-width: 1920px;

	@include media('<phone') {
		padding: 0 0;
	}
}
.expertise__title__wrapper {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
	padding: 0 24px;
	width: max-content;
}

.expertise__subtitle {
	display: none;
	font-size: 16px;
	// margin-bottom: 8px;
	font-weight: 700;

	@include media('<laptop') {
		font-size: 14px;
		// margin-bottom: 6px;
	}
	@include media('<phone') {
		font-size: 12px;
		// margin-bottom: 0px;
	}
}

.expertise__title {
	font-weight: 700;
	font-size: 46px;
	line-height: 42px;
	letter-spacing: -2px;
	text-transform: uppercase;
	white-space: nowrap;
	color: rgb(var(--data-color-black));

	& strong {
		font-style: italic;
	}

	text-align: center;
	margin: 0;
	// margin-bottom: 12px; // removed the price
	margin-bottom: 0;
	width: 100%;

	@include media('<phone') {
		font-size: 30px;
		// margin-bottom: 4px; // removed the price
		line-height: 30px;
	}
}

.expertise__wrapper {
	display: flex;

	@include media('<phone') {
		padding: 0 24px;
	}
}

// Image

.expertise__items__wrapper {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;

	@include media('<phone') {
		margin-top: 0px;
	}
}

.expertise__item__figure {
	height: 100%;
	width: 100%;
	// max-height: 40vh;
	overflow: hidden;
	border-radius: 8px;

	position: relative;
	top: 0;
	left: 0;

	&.active {
		z-index: 1;
	}

	@include media('<phone') {
		border-radius: 0;
	}
}
.expertise__figure__overlap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: z('content-front');
	background: #fffcf7;
	transform: scaleY(1.2);
}
.expertise__item__image {
	border-radius: inherit;
	height: 100%;
	object-fit: cover;
	max-height: inherit;
	// height: 100%;
	width: 100%;

	position: absolute;
	top: 0;
	left: 0;

	&:first-child {
		position: relative;
	}

	&.active {
		z-index: 1;
	}
}

.expertise__bottom {
	@extend %center;
	flex-direction: column;

	margin-top: 0px; // same as gap: 72px;
	width: 100%;

	@include media('<phone') {
		margin-top: 0px;
		align-items: center;
		justify-content: flex-start;
	}
}
.expertise__bottom__list {
	@extend %center;
	@extend %paragraph-16;

	position: relative;
	padding: 0 64px;
	width: 100%;
	min-width: 100%;

	&::-webkit-scrollbar {
		height: 2px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(var(--data-color-black), 0.2);
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(var(--data-color-black));
		outline: 1px solid rgba(var(--data-color-stone), 0.2);
	}

	@include media('<tablet') {
		width: 100%;
		flex-wrap: nowrap;
		padding: 0 0;
		justify-content: flex-start;
		overflow-x: auto;
	}
}
.expertise__bottom__list__line {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: #969696;

	@include media('<tablet') {
		display: none;
	}
}
.expertise__bottom__list__item {
	@extend %paragraph-16;

	padding: 24px 0;

	margin-bottom: 0;
	width: 100%;

	color: #969696;
	text-align: center;
	cursor: pointer;
	user-select: none;
	// margin-left: 24px;

	// &:first-child {
	// 	margin-left: 0;
	// }

	&.active {
		color: rgb(var(--data-color-black));
		background: rgba(var(--data-color-stone), 0.2);
		font-weight: bold;
	}

	&.deactivated {
		display: none;
	}

	&:hover {
		background: rgba(var(--data-color-stone), 0.2);
	}

	@include media('>tablet') {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	@include media('<tablet') {
		width: fit-content;
		margin: 0 0;
		padding: 20px 24px;
		white-space: nowrap;
	}
}

.expertise__bottom__content {
	position: relative;
}
.expertise__bottom__content__text {
	@extend %paragraph-16;

	margin-top: 32px;
	margin-bottom: 64px;
	max-width: 650px;
	min-height: 72px;

	text-align: center;

	@include media('<laptop') {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		max-width: 520px;
	}

	@include media('<phone') {
		margin-top: 12px;
		text-align: justify;
		padding: 0 24px;
		min-height: 144px;
		max-width: 100%;
	}
}

.expertise__line {
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	height: 3vh;
	background: rgb(var(--data-color-stone-black));
	margin-top: 32px;
	// border-radius: 0 8px 8px 0;

	transform-origin: left;
	z-index: z('content');

	@include media('<phone') {
		height: 24px;
	}
}

.expertise__line__txt__wrapper {
	@extend %center;
	@extend %hidden;
	position: absolute;
	bottom: 0;
	width: 100%;
}
.expertise__line__txt {
	@extend %center;
	font-size: 13px;
	color: rgb(var(--data-color-background));
	text-transform: uppercase;
	height: 3vh;
	border-right: 1px rgb(var(--data-color-background)) solid;

	// mix-blend-mode: color-burn;
	// mix-blend-mode: plus-lighter;
	width: 33%;
	text-align: center;
	z-index: z('content-front');

	&:last-child {
		border-right: none;
	}

	@include media('<phone') {
		height: 24px;
	}
}

.expertise .btn {
	// position: absolute;
	bottom: 0;

	visibility: hidden;
	opacity: 0;

	max-width: 300px;
	margin: auto;

	margin-top: 36px;

	// @include media('<desktop') {
	// 	bottom: -96px;
	// }

	// @include media('<phone') {
	// 	bottom: -48px;
	// }
}

.expertise__arrow {
	@extend %center;

	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	height: 100%;
	opacity: 0.7;
	cursor: pointer;

	// border-radius: 0 8px 8px 0;

	transform-origin: left;
	z-index: z('content');

	@include media('>tablet') {
		&:hover {
			opacity: 1;
		}
	}

	@include media('<phone') {
		height: 24px;
	}

	&--right {
		right: 0;
	}

	&--left {
		left: 0;
	}

	svg {
		width: 64px;
		height: auto;

		@include media('<laptop') {
			width: 48px;
		}

		@include media('<phone') {
			width: 24px;
		}
	}
}

.expertise__popup {
	@extend %center;
	position: absolute;
	bottom: 24px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: z('content-front');

	@include media('<desktop') {
		transform: translate(-50%, 0) scale(1);
	}
	@include media('<phone') {
		transform: translate(-50%, 0) scale(1);
		bottom: 0;
	}
}
.expertise__popup__item {
	@extend %center;
	// box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	// backdrop-filter: blur(20px);
	border-radius: 12px;
	position: relative;
	cursor: pointer;

	.arrow-bloom {
		position: absolute;
		top: 0;
		left: 0;
	}

	&--center {
		background: rgba(var(--data-color-background), 0.9);
		padding: 21px 11px;
		overflow: hidden;
		// border-radius: 24px 24px 0 0;
		border-radius: 12px;
		min-width: 543px;

		@include media('<phone') {
			background: rgba(var(--data-color-background), 1);
			padding: 18px 20px;
			border-radius: 20px 20px 0 0;
			min-width: 80vw;
			overflow: hidden;
			z-index: z('content-back');
		}
	}

	&--left,
	&--right {
		padding: 16px;

		transition: background 0s ease-out;
		// backdrop-filter: blur(20px);
		svg path {
			transition: fill 0s ease-out;
		}

		@include media('>tablet') {
			&:hover > svg path {
				fill: #111;
				// fill: rgb(var(--data-color-stone));
			}
		}

		padding: 16px;
		border-radius: 24px;
		position: absolute;
		z-index: z('content');
		opacity: 0.4;

		@include media('<phone') {
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}

	&--left {
		margin-right: 18px;
		left: 0;

		@include media('<phone') {
			margin-right: 20px;
		}
	}

	&--right {
		margin-left: 18px;
		right: 0;

		@include media('<phone') {
			margin-left: 20px;
		}
	}
}

.expertise-get-in-touch__wrapper {
	width: 100%;
	@extend %center;
	max-width: 400px;
	margin: auto;
}

#expertise-get-in-touch {
	max-width: 200px;
}

.expertise__popup__eye {
	@extend %hidden;
	@extend %center;
	position: absolute;
	bottom: 12px;
	left: 50%;

	width: 64px;
	height: 64px;
	padding: 12px;
	overflow: hidden;
	border-radius: 50%;
	transform: translate(-50%, 0) scale(0);
	cursor: pointer;
	background: #f5f3eb;
	z-index: z('content');

	& svg {
		width: inherit;
		height: inherit;
	}

	@include media('<phone') {
		width: 48px;
		height: 48px;
		padding: 10px;
		bottom: 1vh;
	}

	&:hover {
		opacity: 1 !important;
	}
}
