.bg-abs__figure {
	display: flex;
	width: 100%;
	position: relative;
	background: rgb(var(--data-color-black));
}

.bg-abs__image {
	width: inherit;
	height: 100%;
	z-index: z('content');
}
