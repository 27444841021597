.terms {
	@extend %center;
	flex-direction: column;
	background: rgb(var(--data-color-background));

	@include media('<tablet') {
		padding: 4rem 8px;
	}

	@include media('<phone') {
		padding-top: 8rem;
	}
}

.terms__main__heading {
	@extend %bold-62;
	width: 100%;
	text-align: left;
}
.terms__wrapper {
	margin-top: 10vh;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
}
.terms__item {
	margin-bottom: 20px;
	width: 100%;
}
.terms__title {
	@extend %paragraph-24;
	margin-bottom: 4px;
	font-weight: bold;
}
.terms__description {
	@extend %paragraph-16;
	width: 100%;
	max-width: 100%;
	margin-bottom: 12px;
}
.terms__list {
	@extend %paragraph-16;
	list-style: disc;

	padding-left: 24px;
	& li {
		margin-bottom: 8px;

		a {
			@extend %link--hidden;
		}
	}
}
.terms__line-break {
	width: 100%;
	height: 1px;
	background: rgb(var(--data-color-black));
	opacity: 0.1;
	margin-top: 36px;
	margin-bottom: 48px;
}
