.getintouch {
	@extend %center;
	min-height: 0vh;
}
.getintouch__content {
	@extend %center;
	flex-direction: column;
}
.getintouch__header {
	@extend %paragraph-20;
	font-weight: 700;
	margin-bottom: 12px;
}
.getintouch__description {
	@extend %paragraph-16;
	max-width: 350px;
	text-align: center;
}
