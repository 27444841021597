@import './welcome';
@import './expertise';
@import './bg-abs';
@import './awards';
@import './contact';
@import './contactForm';
@import './siteby';
@import './getintouch';

.content.main {
	background: rgb(var(--data-color-background));
	overflow-x: hidden;
}
