footer {
	@extend %center;

	padding: 80px 5vw;
	margin-top: 20vh;
	width: 100%;
	position: relative;

	@include media('<laptop') {
		padding: 80px 24px;
	}

	@include media('<phone') {
		margin-top: 4vh;
	}
}
.footer__container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;

	width: 100%;
	position: relative;

	color: rgb(var(--data-color-black));
	background: rgb(var(--data-color-background));

	& a:hover {
		opacity: 0.75;
	}

	@include media('<laptop') {
		padding: 20px;
	}

	@include media('<phone') {
		padding-bottom: 10px;
	}
}
.footer__container--second {
	align-items: flex-end;
	padding-top: 10px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	.footer__left {
		display: flex;

		a {
			margin-left: 32px;

			&:first-of-type {
				margin-left: 0;
			}
		}

		svg {
			width: 28px;
		}
	}
	.footer__right {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		height: 100%;
	}
	@include media('<tablet') {
		display: flex;
	}
}

.footer__secondary {
	@extend %center;
	flex-direction: column;
	padding-top: 20px;
	padding-bottom: 80px;
}

.footer__center {
	@extend %center;
	flex-direction: column;
	width: 100%;

	a {
		cursor: pointer;
	}
}
.footer__left,
.footer__right {
	width: 100%;

	@include media('<tablet') {
		display: none;
	}
}
.footer__ui__bottom__left {
	display: flex;
	flex-direction: column-reverse;

	& h2 {
		@extend %black-256;
		font-family: $font-kumbh-sans;
		font-size: 42px;
		// line-height: 33px;
	}

	& h3 {
		@extend %paragraph-16;

		margin-bottom: 0;
	}

	@include media('<laptop') {
		margin-bottom: 0px;
	}
}
.footer__right {
	text-align: right;
}
.footer__top {
	background: rgb(246, 244, 242);
	height: 9vh;
	width: 100%;
	border-radius: 0 0 10vh 10vh;
	position: absolute;
	top: 0;
	left: 0;
}
.footer__item {
	@extend %paragraph-16;

	display: flex;
	flex-direction: column;
	line-height: 24px;
	margin-bottom: 0;

	@include media('<phone') {
		margin: 16px;
	}
}
.footer__text {
	@extend %paragraph-16;
	margin-bottom: 0;
	text-align: center;
}

.footer__text--right {
	text-align: right;
	margin: auto;
	margin-right: 0;
}

.footer__text--bold {
	font-weight: 700 !important;
}

.footer__link {
	// @extend %link--hidden;
}
.footer__link:hover {
	opacity: 0.8;
}
footer .signature {
	position: absolute;
	bottom: 30px;
	height: 300px;
	left: 50%;
	transform: translate(-50%, 0%) rotate(-10deg);
	opacity: 0.22;

	@include media('<phone') {
		height: 200px;
	}
}

.footer__logo svg {
	max-width: 17vh;
	position: relative;
	bottom: -1.95vh;

	& path {
		fill: rgb(var(--data-color-black));
	}
}
.footer__logo {
	img {
		width: 199px;
	}
}

.footer__mobileonly {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	margin-top: 36px;
	gap: 18px;

	a {
		height: 34px;
		width: 28px;

		&:hover {
			opacity: 0.75;
		}
	}

	svg {
		width: inherit;
		height: inherit;
	}

	svg path {
		fill: rgb(var(--data-color-black));
	}

	&--siteby {
		display: flex;
		gap: 0;
		flex-direction: column;

		a {
			width: auto;
		}
	}

	@include media('>tablet') {
		display: none;
	}
}
.footer__social-icons--mobile {
	margin-top: 0;
	padding-bottom: 36px;

	@include media('<phone') {
		margin-top: 20px;
		padding-bottom: 20px;
	}
}
.footer__desktoponly {
	@include media('<tablet') {
		display: none !important;
	}
}

.footer__mobileonly__wrapper {
	@extend %center;
	flex-direction: column;

	@include media('>tablet') {
		display: none;
	}
}

.footer__tel {
	@extend %paragraph-16;
	margin: 0 0;
	// margin-top: 2px;
}
